@mixin symbol-category-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  color: var(--color-symbol-category-menu-item);
  background-color: var(--color-symbol-category-menu-item-bg);
  border-bottom: 1px solid var(--color-symbol-category-menu-separator);
  cursor: pointer;

  i {
    width: 44px;
    font-size: 24px;
  }
}

.lncd-sider {
  .lncd-burger-menu {
    @include symbol-category-menu-item();
  }

  .ant-tabs-nav-container-scrolling {
    padding: 0px !important;
  }

  .ant-tabs-tab-arrow-show {
    display: none;
  }

  .ant-tabs-tab {
    // HACK the 170px is width of sider
    width: calc(170px / 2);
    text-align: center;
    user-select: none;
  }
}

// Road layer left side header's style
.lncd-sider .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:nth-child(2) {
  padding-left: 0;
}
