.template-right-box {
  flex: 1;
  border: 1px solid var(--color-border-gray);
  border-radius: 4px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
}
.template-left-box-search,
.template-left-box-search:focus,
.template-left-box-search:hover {
  border: 1px solid var(--color-border-gray) !important;
  border-radius: 4px 4px 0px 0px;
  height: 36px;
}
.template-left-box-content {
  height: 440px;
  border: 1px solid var(--color-border-gray);
  background-color: var(--color-content);
  border-radius: 0px 0px 4px 4px;
  // overflow-y: scroll;
}
.template-card {
  width: 85px;
  height: 103px;
  border: 1px solid var(--color-border-gray) !important;
  border-radius: 4px !important;
  background-color: var(--color-card-bg) !important;
  color: white;
}
.ant-card > div > div {
  text-align: center;
}
.template-card > .ant-card-body {
  border-top: 1px solid var(--color-border-gray);
}
.template-card > .ant-card-cover,
.preview-svg-item,
.template-card > .ant-card-cover > .preview-svg-item > svg {
  width: 83px;
  height: 77px;
  line-height: 77px;
}
.preview-svg-user-defined,
.preview-svg-user-defined > svg {
  width: 220px;
  height: 200px;
}
.preview-template-box,
.preview-template-box > svg {
  width: 200px;
  height: 200px;
}
.template-left-box-search.ant-input-affix-wrapper > input,
.template-left-box-search.ant-input-affix-wrapper > input:hover,
.template-left-box-search.ant-input-affix-wrapper > input:focus {
  border-radius: 0px;
  border: 0px
}
.custom-preview-template {
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  width: 200px;
  height: 200px;
  text-align: center;
  line-height: 200px;
}

.template-left-box-content > div:after {
  content: " ";
  display: block;
  clear: both;
}

.template-left-box-content .ant-collapse-header {
  color: var(--color-symbol-category-menu-item) !important;
  padding: 4px 8px 4px 40px !important;
}
.custom-select-add .ant-select-selection,
.custom-select-add .ant-select-selection:hover,
.custom-select-add .ant-select-selection:focus,
.custom-select-add .ant-select-selection:active {
  // height: 32px;
  color: var(--color-antd-input);
  border: 1px solid var(--color-antd-input-border);
  background-color: transparent;
  box-shadow: initial;
  transition: none;
}
.template-left-box-content .ant-collapse-header {
  color: var(--color-symbol-category-menu-item) !important;
  padding: 4px 8px 4px 40px !important;
}
.template-card-active {
  border: 1px solid var(--color-antd-card-bg) !important;
}
.template-card-active > .ant-card-body {
  background-color: var(--color-antd-card-bg);
}
.template-card-active > .ant-card-body .ant-card-meta-title {
  color: var(--color-top-menu);
}
.add-template-button-container {
  display: inline-block;
  width: 34px;
  height: 34px;
  padding: 5px;
}
.add-template-button {
  font-size: 24px;
}
.lncd-select-suffix {
  font-size: 20px;
  margin-top: -5px;
}

.custom-template-body {
  // padding: 8px 20px 20px;
  display: flex;
  flex-direction: row;
}

.custom-template-save-modal-btns {
  display: flex;
  .custom-template-save-modal-btn {
    margin-right: 10px;
    width: 100px;
  }
}

.template-header {
  display: flex;
  height: 40px;
  margin-bottom: 16px;
}

.template-body {
  display: flex;
  flex-direction: row;
  .template-left-box {
    width: 510px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 600px) {
  .custom-template-body {
    flex-direction: column;
  }
  .custom-preview-template {
    width: 100%;
  }

  .template-body {
    flex-direction: column;
    .template-left-box {
      width: 100%;
      padding: 0;
      margin-bottom: 10px;
    }
    .template-left-box-content {
      height: 150px;
    }
  }
}
