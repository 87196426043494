.custom-info-mask {
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  user-select: none;
  width: 0;
  height: 0;
}

.custom-info-win {
  position: relative;
  background: #fff;
  border-radius: 5px;
}
