.headerContainer {
  display: flex;
  background-color: var(--color-top-menu-bg);
  color: var(--color-top-menu);
}
.headerContainer div {
  display: flex;
  align-items: center;
}

.headerContainer div img {
  height: 34px;
  width: 34px;
  padding: 5px;
  cursor: pointer;
}
.headerContainer div img:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.1s;
}
.headerContainer div img:active {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.1s;
}

.ActionToggle {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.1s;
}

.headerContainer div span:first-child {
  margin-left: 10px;
}
.infill {
  flex: 1 0 auto;
}
.verticalIcon {
  margin-right: 10px !important;
  margin-left: 20px !important;
}
