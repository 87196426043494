.custom_modal_content .ant-tabs-top-content,
.custom_modal_content .ant-tabs-bottom-content {
  width: 100% !important;
  border-bottom: none;
  border-left: none;
}

// .modal-tab {
//   /* display: flex; */
//   padding: 5px 5px;
// }

/* span */
.menulblname {
  /* width: 30px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 28px;
  margin: 0 0 0 5px;
  padding: 0;
}

// FIXME: rename and scope
.lncd-propmenu-select,
.lncd-propmenu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border: 1px solid #e3e3e3;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.lncd-propmenu-select {
  /* background: #bfbfbf; */
  border: 1px solid #1f5078;
}

/* textarea */
 /* FIXME: remove !important */
// .ant-input {
//   box-sizing: border-box !important;
//   width: 100% !important;
//   min-width: auto !important;
//   max-width: auto !important;
//   padding: 4px 6px;
//   overflow: auto !important;
//   /*font-size: 12px !important;*/
//   font-family: Myriad, Helvetica, Tahoma, Arial, clean, sans-serif !important;
//   resize: none !important;
// }

// .horizontal-container {
//   display: flex;
//   align-items: center;
// }

// .height-34 {
//   height: 34px;
// }

.height-28 {
  height: 28px;
}

// .line-height-28 {
//   line-height: 28px;
// }

.ant-row.row-horizontal-center {
  display: flex;
  justify-content: center;
}

/*
  modal for select template begin
*/

.modal-template-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 450px;
  padding: 10px;
  overflow: hidden;
}

.modal-template-left-content {
  display: flex;
  flex-direction: column;
}

.modal-template-right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-template-left-content > * {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.modal-template-left-content .template-list {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
}

.template-group button {
  padding-right: 5px;
  padding-left: 5px;
}

.template-group-title {
  margin: 0;
  padding-right: 5px;
}

.template-search input {
  width: 80% !important;
  margin-right: 5px;
}

.template-list-item {
  /*
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  */
  align-content: center;
  width: 85px;
  min-height: 100px;
  max-height: 116px;
  margin-right: 15px;
  padding: 2px;
  list-style: none;
}

.template-list-item img {
  display: block;
  width: 81px;
  height: 81px;
  border: 1px solid #d9d9d9;
}

.template-list-item img.selected {
  border-color: #333;
}

.template-list-item .desc {
  /* min-height: 16px; */
  width: 81px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.preview-title,
.rotation-title {
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.preview-image {
  width: 155px;
  height: 155px;
  border: 1px solid #d9d9d9;
}

.preview-image img {
  width: 100%;
  height: 100%;
}

.preview-image div,
.preview-image div div {
  width: 100%;
  height: 100%;
}

/*.preview-svg {
  width: 155px;
  height: 155px;
}*/

.template-preview-rotation {
  width: 100%;
}

.template-preview-rotation .ant-input-number {
  width: 155px;
}

.action-bar {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}

.action-bar button {
  margin-right: 5px;
}
.export-input {
  width: 120px !important;
}
/*
  modal for select template end
*/
