.prop-menu-tab-header {
  cursor: pointer;
  padding: 5px;
  min-width: 100px;
  padding: 5px 16px;
  color: var(--color-prop-menu-tab);
  font-weight: 600;
  background-color: var(--color-tab-bg);
  border: 1px solid var(--color-prop-menu-tab-border);
  color: var(--color-tab-color);
  font-style: italic;
  text-align: center;
  white-space: nowrap;

  &:not(.active) + & {
    border-left: none;
  }

  &.active {
    border: none;
    background-color: var(--color-tab-active-bg);
    color: var(--color-prop-menu-tab-active);
  }
}
