@import '~antd/dist/antd.css';
@import './styles/override-antd.scss';
@import './styles/palette.scss';
@import './styles/variables.scss';
@import './styles/menu.scss';


// Please don't use the global style
// FIXME: looks like there's a well known global style issue for ant-design lib.
// https://github.com/ant-design/ant-design/issues/9363
// one possible workaround for this: https://github.com/fi3ework/blog/issues/44
// body {
//   overflow: hidden;
// }

// input,
// button,
// a,
// span {
//   outline: 0 none !important;
// }

// text {
//   user-select: none;
// }

.normal {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  text-align: center;
}

// FIXME: shall we remove this style? if crash designer is inside another container as a component, the height will be larger than expected
// .lncd-layout {
//   height: 100vh;
//   &.isSafari {
//     height: calc(100vh - 75px);
//   }
// }

.custom .ant-drawer-content-wrapper {
  position: absolute;
  width: 500px;
  margin-left: 46px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

@media (max-width: 880px) {
  .lncd-sm-hide {
    display: none !important;
  }
}

@media (min-width: 881px) {
  .lncd-lg-hide {
    display: none !important;
  }
}

/* ----------------------- Styles from SVGRoot --------------------- */

// remove
// [ttn-agent=stripe] > path {
//   stroke: var(--color-stripe-path);
// }
// [lncd-role=crosswalk-line] {
//   stroke: var(--color-crosswalk-line);
// }

// move to inline
// [ttn-role=streetFill] {
//   fill: transparent;
// }
// [ttn-role=crFill] {
//   fill: transparent;
// }
// [lncd-role=crosswalk] {
//   fill: var(--color-canvas-bg);
// }

// todo Curbreturn Color update
/* TODO update default tempalte to remvoe !important */
[ttn-role='visibleCore'] {
  stroke: var(--color-stripe-path) !important;
}

// select layer
// #selector-layer path:not(.handle-point) {
//   stroke: var(--color-selector-layer-stroke);
// }

.handle-point {
  stroke: var(--color-handle-point-stroke);
  stroke-dasharray: 0;
  fill: var(--color-handle-point-fill);
  // make handle shapes translucent so they won't cover the shapes they control
  fill-opacity: 0.65;
  cursor: crosshair;
}

.handle-point.btn {
  cursor: pointer;
}
#canvas g.selected > [ttn-role='streetFill'] {
  fill: var(--color-street-fill-selected);
}
#canvas [lncd-role='stripe-highlight'] {
  fill: none;
  stroke: var(--color-stripe-path-highlight);
  stroke-dasharray: none;
  stroke-width: 20;
  stroke-opacity: 0.5;
}

// FIXME: it's wrong to assume that the stroke/fill of all shapes has the same color
// adaptor
[data-theme='night-mode'] [lncd-night-mode-stroke-adapt] {
  stroke: #{$half-white};
}
[data-theme='night-mode'] [lncd-night-mode-text-adapt] {
  fill: #{$half-white};
}
[data-theme='night-mode'] [lncd-night-mode-fill-adapt] {
  fill: #{$charcoal-black};
}
/* ----------------------- Styles from SVGRoot --------------------- */
