.lncd-message-close {
  margin-left: 10px;
  color: var(--color-global-text) !important;
}

.lncd-message-content {
  display: inline-block;
  padding: 10px 16px 10px 0px;
}

.lncd-message-icon {
  display: inline-block;
  padding: 10px 0px 10px 16px;
}
