.map-sidebar {

  .map-sidebar-btn {
    img {
      width: 38px;
      height: 38px;
      margin-top: 5px;
      padding: 6px;
      background: var(--color-map-side-bg);
      border: 1px solid var(--color-map-side-border-bg);
      border-radius: 4px;
      cursor: pointer;
    }

    &.disabled {
      img {
        background: var(--color-map-side-disabled-bg);
        cursor: not-allowed;
      }
    }
  }
}
