.lncd-layer-menu {
  background-color: var(--color-symbol-category-menu-bg);

  .lncd-layer-menu-body-container {
    // HACK
    // topMenu: 124 + siderSwitchBtn: 41 + footer: 24 + footerPadding = 191
    height: calc(100vh - 191px);
  }
  .lncd-layer-menu-body {
    margin-top: 10px;
    user-select: none;

    .lncd-layer-menu-item {
      width: 100%;
      padding-left: 10px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .lncd-layer-menu__sub-menu {
      text-transform: capitalize;
      font-weight: bold;
      font-style: italic;
    }

    .lncd-layer-menu__sub-menu .ant-tree-switcher {
      color: var(--color-top-menu);
    }

    .lncd-layer-menu__sub-menu-item {
      font-weight: normal;
      font-style: normal;
    }
  }

  .lncd-layer-menu-footer {
    display: flex;
    justify-content: space-evenly;

    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-top-menu);
      background-color: var(--color-top-menu-bg);
      border-color: var(--color-top-menu);
    }

    .ant-btn.top-btn,
    .ant-btn.bottom-btn {
      i {
        transform: rotate(90deg);
      }
    }

    .ant-btn[disabled] {
      background-color: var(--color-prop-menu-btn-disabled);
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  // TODO: override antd
  .ant-tree {
    li.lncd-layer-menu__sub-menu-item span.ant-tree-switcher,
    li span.ant-tree-iconEle {
      display: none;
    }

    li .ant-tree-node-content-wrapper {
      color: var(--color-symbol-category-menu-item);
    }

    li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: var(--color-top-menu);
    }
  }
}
