.custom-number-input.ant-input-number {
  &.custom-default {
    width: 120px;
  }

  &.custom-large {
    width: 240px;
  }

  &.custom-small {
    width: 95px;
  }

  .ant-input-number-input {
    transition: initial;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.color-dropdown {
  width: 80px !important;
}
