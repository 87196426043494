@import '../lncd-sider.scss';

.ant-layout-sider {
  box-shadow: 1px 0 0 var(--color-symbol-bar-border);
}

.search input {
  border-radius: 0 !important;
}

.menu {
  overflow-y: scroll;

  .top-btn,
  .bottom-btn {
    position: absolute;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 24px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      transform: rotate(90deg);
    }
  }

  .bottom-btn {
    bottom: 0;
  }

  ul > li {
    text-align: left;
  }
}

// .rightTitle {
//   display: flex;
// }
// .rightTitle span {
//   flex: 1 0 auto;
//   padding: 5px;
//   border-bottom: 1px solid #ddd;
//   border-left: 1px solid #ddd;
//   cursor: pointer;
// }

// .rightTitle span:first-child {
//   width: 20px;
//   border: 0 !important;
// }

.symbol-category-menu {
  background-color: var(--color-symbol-category-menu-bg);

  &.collapsed .symbol-category {
    span {
      display: none;
    }
  }

  .symbol-category {
    @include symbol-category-menu-item();

    span {
      font-size: 12px;
    }

    &.active {
      color: var(--color-symbol-category-menu-item-active);
      background-color: var(--color-symbol-category-menu-item-bg-active);
      border-bottom-width: 2px;
      border-bottom-color: var(--color-symbol-category-menu-separator-active);

      span {
        font-weight: bold;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-symbol-menu-separator);
  }
}

// .singleContainer img {
//   height: 24px;
//   margin: 5px;
// }

.panel .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.leftItemSelect {
  color: #1f5078;
  border-bottom: 2px solid #1f5078;
}

/* ========== override panel style START============= */

.ant-collapse.symbol-category-menu-collapse {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: initial;

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 4px 0;
    padding-right: 30px;
    padding-left: 5px;
    color: var(--color-symbol-category-menu-header);
    font-size: 12px;
    text-align: left;
    background: var(--color-symbol-category-menu-header-bg);
    border-bottom: 1px solid var(--color-symbol-category-menu-separator);
  }

  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 16px;
    left: initial;
  }

  .ant-collapse-header > span {
    white-space: nowrap;
    font-style: italic;
  }
}

#app-menu-riwcwnb8tt {
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0;
  }
}

#lncd-favorite-search,
#lncd-favorite-search:hover,
#lncd-favorite-search input,
#lncd-favorite-search input:hover,
#lncd-favorite-search input:focus {
  border-width: 0px 0px 1px 0px !important;
  border-radius: 0px !important;
  border-right-width: 0px !important;
  border-color: var(--color-symbol-category-menu-separator) !important;
}

.lncd-auto-fit-text,
.lncd-auto-fit-text > svg {
  color: var(--color-symbol-category-menu-item);
}
/* ========== override panel style  END============= */
