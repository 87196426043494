/** dropdown panel */
.palette-container .ant-dropdown-menu-item {
  cursor: default;
}

.palette-container-body {
  display: flex;
  flex-direction: row;
  user-select: none;
}

.palette-container-body .ant-tabs-content-item {
  cursor: default;
}

.palette-item,
.palette-reset {
  width: 20px;
  height: 20px;
  margin: 1px;
  border: 0.1px solid var(--color-border-gray);
  cursor: pointer;
}

.anticon.palette-reset {
  line-height: normal;
  vertical-align: unset;
  color: var(--color-prop-menu-btn-icon);
  svg {
    width: 100%;
    height: 100%;
  }
}

.palette-outer {
  display: flex;
  align-items: center;
  padding: 0;
  border: 1px solid #d9d9d9;
}

/** dropdown button */
.palette-selected-wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40px;
  padding-left: 5px;
}

.palette-selected {
  width: 26px;
  height: 5px;
  border: 0.1px solid var(--color-border-gray);
}

/* pattern selector begin */

// .selector-pattern-container {
//   position: relative;
// }

// .selector-pattern-selected {
//   position: absolute;
//   top: 5px;
//   left: 5px;
// }
// .background-pattern-selector {
//   /*
//   margin-left: 6px;
//   padding-right: 5px;
//   background: url(/res/freeStripePatternSprite.png) no-repeat;
//   */
//   display: inline-block;
//   width: 39px;
//   height: 18px;
//   text-align: center;
//   background-repeat: no-repeat;
// }

// .background-position-0 {
//   background-position: 0 0;
// }

/* pattern selector end */
