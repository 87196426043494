$row-gutter: 3px;
$col-gutter: 10px;
$row-height: 44px;
$max-height: 2.5 * $row-height;
$symbol-height: $row-height - 2 * $row-gutter;
$symbol-width: 46px;
$label-width: 84px;
$symbol-selected-width: $label-width + $symbol-width + $row-gutter;

$expand-toggler-width: 24px;

// the :export directive is the magic sauce for webpack
:export {
  baseBarHeight: $row-height;
}

[data-theme='night-mode'] {
  .symbol-bar {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
}

.symbol-bar {
  box-sizing: content-box !important;
  height: $row-height;
  overflow: hidden;
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: var(--color-symbol-bar-bg);
  border-top: 1px solid var(--color-symbol-bar-border);
  border-bottom: 1px solid var(--color-symbol-bar-border);

  .symbol-bar-inner {
    text-align: left;
    overflow: hidden;
  }

  .symbol-bar-item {
    float: left;
    box-sizing: border-box;
    cursor: pointer;
    margin: $row-gutter 0 $row-gutter $col-gutter;

    // FIXME: what is this used for?
    // Update symbol size in symbol bar
    &[data-symbol-sub-category="passengers"],
    &[data-symbol-sub-category="pickup"],
    &[data-symbol-sub-category="commercial"],
    &[data-symbol-sub-category="emergency"],
    &[data-symbol-sub-category="marine"],
    &[data-symbol-sub-category="recreational"],
    &[data-symbol-sub-category="rail"],
    &[data-symbol-sub-category="other"] {
      $symbol-width: 66px;
      $symbol-selected-width: $label-width + $symbol-width + $row-gutter;
      &.symbol-bar-item-selected {
        width: $symbol-selected-width;
      }
      .symbol-image-wrapper {
        width: $symbol-width;
      }
    }
    &[data-symbol-sub-category="semi-trailers"] {
      $symbol-width: 106px;
      $symbol-selected-width: $label-width + $symbol-width + $row-gutter;
      &.symbol-bar-item-selected {
        width: $symbol-selected-width;
      }
      .symbol-image-wrapper {
        width: $symbol-width;
      }

      // XXX: special one
      &[data-symbol-key="SemiTruck"] {
        $symbol-width: 46px;
        $symbol-selected-width: $label-width + $symbol-width + $row-gutter;
        &.symbol-bar-item-selected {
          width: $symbol-selected-width;
        }
        .symbol-image-wrapper {
          width: $symbol-width;
        }
      }
    }

    // 宽度自适应的 symbol 样式
    &.lncd-symbol-adapter {
      &.symbol-bar-item-selected {
        .symbol-image {
          max-width: 100%;
        }
      }

      .symbol-image-wrapper {
        .symbol-image {
          max-height: none;
        }
      }
    }
  }

  .symbol-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // width: 100%;
    width: $symbol-width;
    height: $symbol-height;
    position: relative;

    .lncd-favorite-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 0.8em;
      color: var(--color-favorite-icon);
    }
  }

  .symbol-image {
    max-width: 100%;
    max-height: 100%;

    // XXX: Prevent symbol image icons will cover each other.
    pointer-events: none;
  }

  .symbol-image-phantom {
    position: absolute;
    z-index: -1;
  }

  .expand-toggler {
    position: absolute;
    width: $expand-toggler-width;
    height: $row-height;
    left: calc(100vw - #{$expand-toggler-width});
    bottom: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    transition: initial;
    z-index: 3;
  }

  .symbol-bar-item-selected {
    display: flex;
    background-color: var(--color-symbol-item-bg-active);
    color: var(--color-symbol-item-active);
    width: $symbol-selected-width;
    height: $row-height;
    text-align: left;
    padding: $row-gutter 0 $row-gutter $col-gutter;
    margin: 0;

    & + .symbol-bar-item {
      margin-left: 0;
    }

    .symbol-image-wrapper {
      background-color: var(--color-symbol-icon-bg-active);
      border-radius: 3px;
      width: $symbol-width;
    }

    .symbol-image {
      max-width: $symbol-width;

      // XXX: Prevent symbol image icons will cover each other.
      pointer-events: none;
    }

    .symbol-label {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      overflow: hidden;
      word-wrap: normal;
      text-align: center;
      padding: 0 5px;
    }
  }

  .overlay {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $symbol-height - $row-gutter;
    background-image: linear-gradient(rgba(255, 255, 255, 0), var(--color-top-menu-overlay));
    touch-action: none;
    pointer-events: none;
    z-index: 2;
  }

  &.symbol-bar-expanded {
    z-index: 2;
    height: auto;

    .symbol-bar-inner {
      overflow-y: auto;
      max-height: $max-height;
    }
  }
}

.symbol-avatar {
  position: absolute;
  background-color: transparent;
  display: inline-block;
  pointer-events: none;
  user-select: none;
  transform: translate(-50%, -50%);
}

.symbol-thumb-object {
  opacity: 0.65;
}
