.lncd-more-menu > .ant-menu {
  background: none;
  border-width: 0px !important;
}

.lncd-more-menu > .ant-menu > .ant-menu-submenu {
  display: block;
}

.anticon.anticon-more {
  color: white;
}

.lncd-more-menu .ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  // display: block !important;
  top: 0px;
  margin: 0px !important;
  border: 0px !important;
  color: white;
  // margin-top: -4px !important;
}

.lncd-more-menu .ant-menu-submenu-title {
  padding: 0;
  max-height: 40px;
}

// HACK: 41px is menu's line height
.lncd-more-submenu {
  top: 41px !important;
  background-color: var(--color-top-menu-bg) !important;
  &.toolbar-submenu {
    top: 82px !important;
  }
}

.lncd-more-submenu > .ant-menu {
  background-color: var(--color-top-menu-bg) !important;
}

.lncd-more-submenu > .ant-menu > .ant-menu-item-selected {
  // width: 100px;
  background: none !important;
}

// FIXME: DRY with another header.scss file
.lncd-more-submenu .top-menu-bar-btn,
.lncd-more-submenu .toolbar-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 34px;
  font-size: 24px;
  color: var(--color-top-menu);
  cursor: pointer;
  transition: all 0.1s;
}

.lncd-more-submenu .toolbar-section {
  padding: 0;
  border-right: 0;
}

.lncd-more-submenu .top-menu-bar-btn.active,
.lncd-more-submenu .toolbar-section.active {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.lncd-setting-body {
  min-height: 120px;
}

.lncd-setting-item {
  line-height: 1.5em;
  margin-top: 24px;
  max-height: 380px;

  & > section {
    color: var(--color-symbol-category-menu-item);
  }
  & > label {
    width: 120px;
    display: inline-block;
  }
}

.lncd-setting-item .language-select {
  width: 120px;
}

.lncd-setting-item .eula-container {
  min-height: 380px;
  border: none;
}
