.lncd-menu,
.lncd-submenu,
.lncd-submenu > ul,
.lncd-menu .ant-menu-submenu-title,
.lncd-submenu .ant-menu-submenu-title,
.lncd-submenu .ant-menu-item-disabled,
.lncd-submenu .ant-menu-item:visited,
.lncd-submenu .ant-menu-item:active,
.lncd-submenu .ant-menu-item-selected {
  text-align: left;
  border-width: 0px !important;
  color: var(--color-global-text) !important;
  margin: 0px !important;
  user-select: none;
}

.lncd-menu,
.lncd-submenu,
.lncd-submenu .ant-menu-item:visited,
.lncd-submenu .ant-menu-item:active,
.lncd-menu .ant-menu-submenu-title:visited,
.lncd-menu .ant-menu-submenu-title:active,
.lncd-submenu > ul {
  background-color: var(--color-modal-bg) !important;
}

.lncd-menu,
.lncd-menu .ant-menu-submenu-title {
  width: 120px;
}

.lncd-submenu,
.lncd-submenu > ul,
.lncd-submenu .ant-menu-submenu-title {
  width: 160px;
}

.lncd-menu .ant-menu-submenu-title,
.lncd-submenu .ant-menu-item {
  height: 36px !important;
  line-height: 36px !important;
  margin: 0px !important;
}

.lncd-menu > .ant-menu-submenu:hover,
.lncd-menu .ant-menu-submenu-title:hover,
.lncd-submenu .ant-menu-item-disabled:hover,
.lncd-submenu .ant-menu-item-active {
  color: var(--color-global-text) !important;
  background-color: var(--color-select-option-hover) !important;
}

.lncd-submenu .ant-menu-item-selected {
  color: var(--color-global-text) !important;
  background-color: var(--color-select-option-selected) !important;  
}

.lncd-menu .ant-menu-submenu-arrow::before,
.lncd-menu .ant-menu-submenu-arrow::after {
  background: var(--color-global-text) !important;
}
