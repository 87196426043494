/* define standard CSS variables */
@import "./palette.scss";

.road-engine-container,
[data-theme="day-mode"],
.ant-modal-root .day-mode.night-mode-modal,
.ant-select-dropdown.day-mode {
  // canvas area
  --color-canvas-bg: #{$white};
  --color-stripe-path: #{$black};
  --color-stripe-path-highlight: #{$blue};
  --color-street-fill-selected: #{$whitesmoke};
  // --color-street-fill-selected: #{$half-transparent-white};
  --color-crosswalk-line: #{$black};
  --color-selector-layer-stroke: #{$blue};

  // general items
  --color-input-addon: #{$black};

  // top menu bar
  --color-top-menu-bg: #{$blue};
  --color-top-menu: #{$grey-blue};
  --color-top-menu-overlay: #{$whitesmoke};
  --color-top-menu-item-disabled: #{$half-dark-transparent-white};

  // tool bar
  --color-toolbar-bg: #{$whitesmoke};
  --color-toolbar-separator: #{$lightgrey};
  --color-toolbar-item: #{$grey};
  --color-toolbar-item-bg-active: #{$half-transparent-black};
  --opacity-toolbar: 1;

  // symbol category menu & symbol bar
  --color-symbol-bar-border: #{$lightgrey};
  --color-symbol-bar-bg: #{$whitesmoke};
  --color-symbol-item-active: #{$white};
  --color-symbol-item-bg-active: #{$night-blue};
  --color-symbol-icon-bg-active:#{$white};
  --opacity-symbol-icon-bg-active: 1;
  --color-symbol-category-menu-bg:#{$white};
  --color-symbol-category-menu-item: #{$grey};
  --color-symbol-category-menu-item-active:#{$blue};
  --color-symbol-category-menu-item-bg:#{$white};
  --color-symbol-category-menu-item-bg-active: #{$whitesmoke};
  --color-symbol-category-menu-header: #{$dim-white};
  --color-symbol-category-menu-header-bg:#{$blue};
  --color-symbol-category-menu-separator: #{$lightgrey};
  --color-symbol-category-menu-separator-active:#{$blue};

  // pin sidebar
  --color-pin-sidebar: #{$blue};

  // bottom menu - property menu
  --color-prop-menu: #{$black};
  --color-prop-menu-bg:#{$white};
  --color-prop-menu-border: #{$lightgrey};
  --color-prop-menu-toggler: #{$grey};
  --color-prop-menu-tab: #{$grey};
  --color-prop-menu-tab-active:#{$blue};
  --color-prop-menu-tab-bg: #{$whitesmoke};
  --color-prop-menu-tab-bg-active:#{$white};
  --color-prop-menu-tab-border: #{$lightgrey};
  --color-prop-menu-btn: #{$black};
  --color-prop-menu-btn-hover: #{$blue};
  --color-prop-menu-btn-icon: #{$black};
  --color-prop-menu-btn-border:#{$black};
  --color-prop-menu-btn-disabled:#{$lightgrey};
  --color-prop-menu-btn-icon-active: #{$blue};
  --color-prop-menu-btn-border-active:#{$blue};
  --color-prop-menu-item-label: #{$black};
  --color-prop-menu-dropdown: #{$black};
  --color-prop-menu-dropdown-border: #{$black};
  --color-prop-menu-checkbox-border:#{$blue};
  --color-prop-menu-checkbox:#{$blue};

  // TODO: template modal menu


  // TODO: override antd components
  --color-antd-menu-item: #{$grey};
  --color-antd-btn-primary: #{$dim-white};
  --color-antd-btn-primary-bg: #{$blue};
  --color-antd-input: #{$black};
  --color-antd-input-border: #{$grey};

  // handle point
  --color-handle-point-fill: #{$white};
  --color-handle-point-stroke: #{$blue};

  // modal
  --color-modal-bg: #{$white};
  --color-modal-border: #{$day-border-black};
  // tab
  --color-tab-bg: #{$light-gray};
  --color-tab-color: #{$light-color-gray};
  --color-tab-active-bg: #{$white};
  --color-tab-active-color: #{$light-gray-active};

  --color-border-gray: #{$border-gray};
  --color-content: #{$white};
  --color-card-bg: #{$white};

  --color-select-option-hover: #{$alice-blue};
  --color-select-option-selected: #{$white};
  --color-scroll-bar: #{$light-color-gray};

  --color-antd-card-bg: #{$blue};

  --color-placeholder-text: #{$lightgrey};

  // favorite
  --color-favorite-icon: #{$blue};

  // map side bar
  --color-map-side-bg: #{$white};
  --color-map-side-border-bg: #{$border-gray};
  --color-map-side-disabled-bg: #{$lightgrey};
  // global color for text
  --color-global-text: #{$grey};

  // disable color
  --color-disabled: #{$whitesmoke};
  // text color
  --color-text-middle-color: #{$half-white};
  // loading color
  --color-loading-spin: #{$blue};
  --color-loading-mask: #{$half-white};
}

[data-theme="night-mode"],
.ant-modal-root .night-mode.night-mode-modal,
.ant-select-dropdown.night-mode {
  // canvas area
  --color-canvas-bg: #{$canvas-bg-black};
  --color-stripe-path: #{$half-white};
  --color-stripe-path-highlight: #{$half-white};
  --color-street-fill-selected: #{$canvas-bg-black};
  // --color-street-fill-selected: #{$transparent-white};
  --color-crosswalk-line: #{$half-white};
  --color-selector-layer-stroke: #{$lightgrey};

  // general items
  --color-input-addon: #{$half-white};

  // top menu bar
  --color-top-menu-bg: #{$dark-blue};
  --color-top-menu: #{$half-white};
  --color-top-menu-overlay: #{$night-border};
  --color-top-menu-item-disabled: #{$half-dark-transparent-white};

  // tool bar
  --color-toolbar-bg: #{$charcoal-black};
  --color-toolbar-separator: #{$night-border};
  --color-toolbar-item: #{$white};
  --color-toolbar-item-bg-active: rgba(0, 0, 0, 0.8);
  --opacity-toolbar: 0.4;

  // symbol category menu & symbol bar
  --color-symbol-bar-border: #{$night-border};
  --color-symbol-bar-bg: #{$charcoal-black};
  --color-symbol-item-active: #{$half-white};
  --color-symbol-item-bg-active: #{$charcoal-black};
  --color-symbol-icon-bg-active: #{$dim-white};
  --opacity-symbol-icon-bg-active: 1;
  --color-symbol-category-menu-bg:#{$charcoal-black};
  --color-symbol-category-menu-item: #{$half-white};
  --color-symbol-category-menu-item-active:#{$dim-white};
  --color-symbol-category-menu-item-bg:#{$charcoal-black};
  --color-symbol-category-menu-item-bg-active: #{$charcoal-black};
  --color-symbol-category-menu-header: #{$half-white};
  --color-symbol-category-menu-header-bg:#{$dark-blue};
  --color-symbol-category-menu-separator: #{$night-border};
  --color-symbol-category-menu-separator-active:#{$dim-white};

  // pin sidebar
  --color-pin-sidebar: #{$dark-blue};

  // bottom menu - property menu
  --color-prop-menu: #{$half-white};
  --color-prop-menu-bg: #{$charcoal-black};
  // TODO: not in use?
  --color-prop-menu-border: #{$night-border};
  --color-prop-menu-toggler: #{$grey};
  --color-prop-menu-tab: #{$half-white};
  --color-prop-menu-tab-active:#{$blue};
  --color-prop-menu-tab-bg: #{$charcoal-black};
  --color-prop-menu-tab-bg-active:rgba(22, 22, 24, 1);
  --color-prop-menu-tab-border: transparent;
  --color-prop-menu-btn: #{$half-white};
  --color-prop-menu-btn-hover: #{$blue};
  --color-prop-menu-btn-icon: #{$half-white};
  --color-prop-menu-btn-border:#{$night-border};
  --color-prop-menu-btn-disabled:#{$night-color-gray};
  --color-prop-menu-btn-icon-active: #{$blue};
  --color-prop-menu-btn-border-active:#{$blue};
  --color-prop-menu-item-label: #{$half-white};
  --color-prop-menu-dropdown: #{$half-white};
  --color-prop-menu-dropdown-border: #{$night-border};
  --color-prop-menu-checkbox-border: #{$night-border};
  --color-prop-menu-checkbox: #{$blue};

  // TODO: template modal menu


  // TODO: override antd components
  --color-antd-menu-item: #{$grey};
  --color-antd-btn-primary: #{$dim-white};
  --color-antd-btn-primary-bg: #{$blue};
  --color-antd-input: #{$half-white};
  --color-antd-input-border: #{$night-border};

  // handle point
  --color-handle-point-fill: #{$white};
  --color-handle-point-stroke: #{$blue};

  // modal
  --color-modal-bg: #{$night-bg-black};
  --color-modal-border: #{$night-border-black};
  // tab
  --color-tab-bg: #{$night-gray};
  --color-tab-color: #{$night-color-gray};
  --color-tab-active-bg: #{$night-gray-active};
  --color-tab-active-color: #303c4e;

  --color-border-gray: #{$night-border-gray};
  --color-content: #{$deep-dark};
  --color-card-bg: #{$card-black};

  --color-select-option-hover: #{$night-gray};
  --color-select-option-selected: #{$night-bg-black};
  --color-scroll-bar: #{$light-color-gray};

  --color-antd-card-bg: #{$blue};

  --color-placeholder-text: #{$lighter-gray};

  // favorite
  --color-favorite-icon: #{$dim-white};

  // map side bar
  --color-map-side-bg: #{$night-gray-active};
  --color-map-side-border: #{$night-border-gray};
  --color-map-side-disabled-bg: #{$night-color-gray};
  // global color for text
  --color-global-text: #{$grey};

  // disable color
  --color-disabled: #{$grey};
  // text color
  --color-text-middle-color: #{$half-white};
  // loading color
  --color-loading-spin: #{$blue};
  --color-loading-mask: #{$half-black};
}
