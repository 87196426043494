$btn-size: 34px;

.top-menu-bar {
  height: 40px;

  .top-menu-bar-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: $btn-size;
    width: $btn-size;
    font-size: 24px;
    color: var(--color-top-menu);
    transition: all 0.1s;
  }

  .top-menu-bar-btn-enabled:active {
    border-radius: 2px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }

  .top-menu-bar-btn-enabled:hover {
    cursor: pointer;
    border-radius: 2px;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }

  .section-label {
    margin-right: 5px;
  }
}

.toolbar {
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--color-toolbar-bg);
}

.toolbar-section {
  display: flex;
  align-items: center;
  padding: 0 12px;
  color: var(--color-toolbar-item);
  border-right: 1px solid var(--color-toolbar-separator);
  opacity: var(--opacity-toolbar);

  &:nth-last-child(1) {
    border-right: none;
  }

  label {
    margin-right: 5px;
  }

  .toolbar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    min-width: 36px;
    padding: 0 5px;
    cursor: pointer;

    &:hover,
    &:active {
      border-radius: 2px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      transition: all 0.1s;
    }

    &:hover {
      background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }

    &:active,
    &.selected {
      background: var(--color-toolbar-item-bg-active);
    }

    .anticon {
      font-size: 24px;
    }

    .anticon.caret-down {
      font-size: 10px;
    }
  }
}

// see: src\index.scss
@media (max-width: 880px) {
  .toolbar-section {
    padding: 0 5px;
  }
}
