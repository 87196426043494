/* color palette, that's all */

$white: #ffffff;
$whitesmoke: rgb(245, 245, 245);
$dim-white: rgba(255, 255, 255, 0.85);
$half-white: rgba(255, 255, 255, 0.5);
$half-transparent-white: rgba(255, 255, 255, 0.3);
$half-dark-transparent-white: rgba(255, 255, 255, 0.15);
$transparent-white: rgba(204, 204, 204, 0.09);

$black: #000000;
$half-black: rgba(0, 0, 0, 0.5);
$half-transparent-black: rgba(0, 0, 0, 0.3);
$charcoal-black: rgb(30, 30, 30);

// $dark-blue: rgb(14, 32, 49);
$dark-blue: rgb(17, 40, 61);
$blue: rgb(34, 80, 122);
$night-blue: rgb(90, 124, 156);
$grey-blue: rgb(173, 199, 221);

$grey: rgb(117, 117, 117);
$lightgrey: rgb(211, 211, 211);

$night-border: rgb(36, 37, 39);

$night-bg-black: rgb(28, 30, 29);

$night-border-black: rgb(22, 22, 24);
$day-border-black: rgb(232, 232, 232);

$light-gray: rgb(240, 240, 240);
$light-color-gray: rgb(133, 133, 133);
$light-gray-active: rgb(87, 110, 126);

$night-gray: rgb(21, 23, 24);
$night-color-gray: rgb(82, 84, 85);
$night-gray-active: rgb(28, 30, 29);

$border-gray: rgb(220, 220, 220);
$night-border-gray: rgb(67, 69, 71);

$deep-dark: rgb(22, 22, 24);
$deep-white: rgb(247, 244, 248);

$card-black: rgb(36, 38, 39);

$canvas-bg-black: rgb(19, 20, 21);

$lighter-gray: rgb(88, 88, 88);
$alice-blue: rgb(230, 247, 255);

$bold-font-weight: 600;

:export {
  day: {
    strokecolor: $black;
    canvasbgcolor: $white;
  }
  night: {
    strokecolor: $half-white;
    canvasbgcolor: $canvas-bg-black;
  }
}
