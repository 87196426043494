@import './palette.scss';

// FIXME: use variable replacement instead
button.ant-btn.ant-btn-primary,
.ant-modal-footer > div > button.ant-btn.ant-btn-primary,
.ant-modal-confirm-btns > button.ant-btn.ant-btn-primary {
  color: var(--color-antd-btn-primary);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--color-antd-btn-primary-bg);
  border: none;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}
.ant-modal-confirm-btns > button.ant-btn,
.ant-modal-footer > div > button.ant-btn,
button.ant-btn.ant-btn-default {
  color: var(--color-symbol-category-menu-item);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--color-modal-bg);
  // border: none;
  // -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
  // box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
}
button.ant-btn-ghost,
button.ant-btn-ghost:hover {
  color: var(--color-symbol-category-menu-item);
  border-color: var(--color-symbol-category-menu-item);
}

button.ant-btn,
button.ant-btn:hover {
  border-color: var(--color-symbol-category-menu-item);
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0;
}

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-input-number,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-select-selection,
.ant-select-disabled .ant-select-selection {
  color: var(--color-antd-input);
  border: 1px solid var(--color-antd-input-border);
  background-color: transparent;
  box-shadow: initial;
  transition: none;
}

.ant-input[disabled],
.ant-input[disabled]:hover {
  color: var(--color-prop-menu-btn-disabled);
  border: 1px solid var(--color-antd-input-border);
  background-color: var(--color-top-menu-item-disabled);
  cursor: not-allowed;
  opacity: 1;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

// FIXME: wrong color variable name
.ant-select-dropdown,
.ant-dropdown-menu {
  background-color: var(--color-modal-bg);
}

.ant-select-dropdown-menu-item,
.ant-dropdown-menu-item,
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover,
.ant-dropdown-menu-submenu > .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu > .ant-dropdown-menu-submenu-title > .ant-dropdown-menu-submenu-arrow > i {
  color: #757575;
}

.ant-select-dropdown-menu-item-disabled,
.lncd-submenu .ant-menu-item.ant-menu-item-disabled,
.lncd-submenu .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
  color: rgba(117, 117, 117, 0.5) !important;
}

.ant-dropdown-menu-item > svg path,
.custom-dropdown-item > span > svg path {
  stroke: #757575;
}

.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item-selected,
.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover,
.ant-dropdown-menu-submenu-active > .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu-selected > .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu-active > .ant-dropdown-menu-submenu-title > .ant-dropdown-menu-submenu-arrow > i,
.ant-dropdown-menu-submenu-selected > .ant-dropdown-menu-submenu-title > .ant-dropdown-menu-submenu-arrow > i,
.ant-list-item:hover {
  background-color: var(--color-select-option-hover) !important;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-card-meta-title {
  font-size: 13px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  font-weight: normal;
}
.add-template-button-container {
  display: inline-block;
  width: 34px;
  height: 34px;
  padding: 5px;
}
.add-template-button {
  font-size: 24px;
}
.ant-tabs-nav.ant-tabs-nav-animated > div {
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: var(--color-prop-menu-tab-border);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin-right: 0px;
  border-width: 0px 1px 1px 0px;
  background-color: var(--color-tab-bg);
  border-color: var(--color-prop-menu-tab-border);
  color: var(--color-tab-color);
  border-radius: 0px;
  font-style: italic;
  font-weight: $bold-font-weight;
}
/*
 * 解决 Safari 中切换 Tabs 内容会闪一下消失的问题
 * https://github.com/ant-design/ant-design/issues/23050
 */
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  -webkit-backface-visibility: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: var(--color-tab-active-bg);
  color: var(--color-prop-menu-tab-active);
}
.ant-tabs-bar {
  margin: 0;
  background-color: var(--color-tab-active-bg);
  border-color: var(--color-tab-active-bg);
}
.ant-modal-close .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
  // margin-right: 12px;
  text-align: center;
}

.ant-modal-footer {
  border-top: none;
}

.ant-select-disabled .ant-select-selection:hover {
  height: 32px;
  color: var(--color-antd-input);
  border: 1px solid var(--color-antd-input-border);
  background-color: transparent;
  box-shadow: initial;
  transition: none;
}

.ant-modal-content {
  max-width: 100%;
}

.ant-modal-content,
.ant-modal-header,
.ant-modal-header > .ant-modal-title,
.ant-modal-close-x,
.ant-modal-body {
  background-color: var(--color-modal-bg);
  color: var(--color-symbol-category-menu-item);
  text-align: left;
}
.ant-modal-header,
.ant-modal-footer {
  border-color: var(--color-modal-border);
}
.ant-modal-confirm-title {
  color: var(--color-symbol-category-menu-item) !important;
}
.ant-card-meta-title {
  color: var(--color-symbol-category-menu-item);
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px;
}
input::-webkit-input-placeholder,
.ant-select-selection__placeholder {
  color: var(--color-placeholder-text) !important;
  font-size: 14px;
}

.ant-list-bordered {
  border: 1px solid var(--color-border-gray);
}
.ant-list-bordered .ant-list-item {
  border-bottom: 1px solid var(--color-border-gray);
  color: var(--color-symbol-category-menu-item);
}
.custom-night-mode-text,
.ant-empty-description {
  color: var(--color-symbol-category-menu-item);
}
.ant-collapse {
  border: none;
  border-radius: 0px;
}
.ant-collapse-header {
  background-color: var(--color-border-gray);
  text-align: left;
}
.ant-collapse-content-box {
  padding: 0px !important;
}
.ant-collapse > .ant-collapse-item {
  background-color: var(--color-modal-bg);
  color: var(--color-symbol-category-menu-item);
  border-radius: none;
}
.ant-collapse-content {
  background-color: var(--color-modal-bg);
  color: var(--color-symbol-category-menu-item);
  border-radius: none;
  border-top: 1px solid var(--color-modal-bg);
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid var(--color-modal-bg);
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0px;
}
.ant-message-notice-content {
  background-color: var(--color-modal-bg);
  color: var(--color-symbol-category-menu-item);
  padding: 0px !important;
  user-select: none;
}

.ant-modal-confirm .ant-modal-confirm-btns:after,
.ant-modal-footer > div:after {
  content: ' ';
  display: block;
  clear: both;
}
.ant-modal-confirm-btns > button.ant-btn,
.ant-modal-footer > div > button.ant-btn {
  float: right;
}
.ant-modal-confirm-btns > button.ant-btn.ant-btn-primary,
.ant-modal-footer > div > button.ant-btn.ant-btn-primary {
  margin-left: 0px;
  margin-right: 8px;
}

.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span:not(.ant-dropdown-menu-submenu-arrow) > .anticon:first-child {
  font-size: 24px;
  color: var(--color-antd-menu-item);
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  -moz-user-select: none;
  -webkit-user-select: none;
}

@media (max-height: 680px) {
  .ant-modal {
    top: 20px;
  }
}

.addon-group > button.ant-btn > .anticon {
  font-size: 24px;
  color: var(--color-input-addon);
}

.ant-tabs-nav-scroll {
  text-align: left;
}
.ant-select-dropdown {
  text-align: left;
}
.ant-select-selection__choice {
  background-color: var(--color-tab-bg) !important;
  border-color: var(--color-tab-bg) !important;
  color: var(--color-antd-input) !important;
}
.ant-select-selection__choice__remove {
  color: var(--color-antd-input) !important;
}
.ant-message-notice-content {
  border: 1px solid var(--color-border-gray);
}

.ant-tooltip-content {
  user-select: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  &,
  &:hover {
    background: var(--color-antd-btn-primary-bg);
    border-color: var(--color-antd-btn-primary-bg);
    color: var(--color-antd-btn-primary);
    box-shadow: none;
    transition: none;
  }
}

.ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid var(--color-antd-btn-primary-bg);
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper:last-child {
  border-right: 1px solid var(--color-antd-btn-primary-bg);
  border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper,
.ant-radio-button-wrapper:hover {
  background: initial;
  color: var(--color-antd-input);
  // TODO: set border variable
  border: 1px solid rgb(31, 80, 120);
}

/** ant-design-draggable-modal **/
/** hide resize handle **/
.ant-design-draggable-modal-resize-handle {
  display: none;
}

.ant-table .ant-table-content .ant-table-placeholder {
  background-color: transparent;
}

.ant-table-small,
.ant-table-small.ant-table-bordered .ant-table-content {
  border-color: var(--color-symbol-category-menu-item);
}

.ant-table .ant-table-row-cell-break-word,
.ant-table .ant-table-column-title,
.ant-table-footer {
  color: var(--color-symbol-category-menu-item);
}
