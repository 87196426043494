$row-height: 70px;
$max-height: 1.5 * $row-height;

@mixin prop-menu-item-btn {
  padding: 0 3px;

  .anticon {
    font-size: 24px;
    color: var(--color-prop-menu-btn-icon);
  }

  .anticon.dropdown-indicator {
    font-size: 10px;
  }
}

.prop-menu-root {
  .ant-drawer-content {
    color: var(--color-prop-menu);
    background-color: var(--color-prop-menu-bg);
  }
}

.custom-dropdown {
  border: 1px solid var(--color-prop-menu-dropdown-border);
  width: 180px;
  height: 32px;
  border-radius: 4px;
  color: var(--color-prop-menu-dropdown);
  cursor: pointer;

  &.map-side-bar {
    width: 125px;
    margin-top: 5px;
    background: var(--color-prop-menu-bg);
  }

  &.text-box-select {
    width: 95px;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}
.custom-dropdown-no-border {
  border: 0px solid rgb(41, 34, 34) !important;
  height: 32px;
  border-radius: 4px;
  color: black;
}
.custom-dropdown-no-hover .ant-dropdown-menu-item {
  background-color: unset !important;
}
.custom-dropdown-item {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: center;
  transform-origin: top right;
}

// .custom-checkbox {
//   height: 28px;
//   width: 28px;
//   border: 1px solid #1f5078;
//   border-radius: 2px;
//   margin-top: 3px;
// }

.ant-btn.lncd-button {
  background: transparent;
  color: var(--color-prop-menu-btn);
  &:focus, &:hover {
    color: var(--color-prop-menu-btn-hover);
    border-color: var(--color-prop-menu-btn-hover);
  }
}

/** props-container **/

.props-container {
  // display: flex;
  padding: 0 10px;
  justify-content: flex-start;
  max-height: $max-height;
  overflow-y: auto;
  overflow-x: hidden;

  .container-item {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;

    textarea.ant-input {
      width: 200px;
      resize: none;
    }

    .ant-btn {
      background-color: transparent;
      // border-color: var(--color-prop-menu-btn-border);

      @include prop-menu-item-btn;
    }

    .ant-btn.active {
      border-color: var(--color-prop-menu-btn-border-active);
      border-width: 2px;

      .anticon {
        color: var(--color-prop-menu-btn-border-active);
      }
    }

    .ant-btn + .ant-btn {
      margin-left: 10px;
    }

    .color-dropdown {
      height: 32px;
      @include prop-menu-item-btn;
      display: flex;
      align-items: center;
    }

    .addon-group .ant-btn .anticon {
      color: var(--color-input-addon);
    }

    &.hide-divider,
    .hide-shoulder {
      margin: auto 15px;
    }
  }
}

.props-container:after {
  content: ' ';
  display: block;
  clear: both;
}

/** **/

.item-title,
.item-title-space::before {
  display: inline-block;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  margin: 5px 0;
  color: var(--color-prop-menu-item-label);
}

.item-title-space::before {
  content: '\200b';
}
