.map-modal {
  padding: 10px 0;
  .map-modal-header {
    display: flex;
    justify-content: space-between;
  }
  .map-modal-body {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    .left-section {
      flex: 0 48%;
    }
    .right-section {
      display: flex;
      flex: 0 48%;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.map-modal-body {
  .lncd-map-modal-title {
    font-weight: bold;
  }
  .article {
    display: flex;
    align-items: center;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.lncd-slider .ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #94b6d1;
  border-radius: 50%;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  cursor: pointer;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
    -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.lncd-slider .ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #94b6d1;
  border-radius: 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
