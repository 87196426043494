// override-antd
#lncd-measurement-table {
  a {
    color: var(--color-prop-menu-btn);
    &:hover {
      color: var(--color-prop-menu-btn-hover);
    }
  }

  a[disabled] {
    color: var(--color-prop-menu-btn-disabled);
  }

  .ant-table {
    line-height: 1em;
  }

  .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-thead > tr > th {
    background: none;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 4px;
    white-space: nowrap;
  }

  tr:hover > td {
    background: unset;
  }

  .ant-table-scroll > .ant-table-header {
    margin-bottom: 0 !important;
  }

  .ant-form-item-control {
    line-height: normal;
  }

  .ant-input {
    min-width: calc(14px + 1em);
  }
}

// scrollbar
#lncd-measurement-table {
  .lncd-measurement-scrollbar {
    max-width: 100%;
    max-height: 198px;
    overflow: auto;
  }
}
#road-engine-container #lncd-measurement-table .lncd-measurement-scrollbar {
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll-bar);
    border-radius: 2px;
  }
}
