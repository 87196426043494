@import '../../styles/prop-menu.scss';

.reg-no-select {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

#canvas {
  width: 100%;
  // height: 100%;
  height: calc(100vh - 124px);
  overflow: hidden;
  background-color: var(--color-canvas-bg);
}

// prevent default scrollbar,use react-scrollbar
#road-engine-container *::-webkit-scrollbar {
  display: none;
}
