.map-search {
  margin: 5px;
  .map-search-head {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  .map-search-content {
    position: relative;
    margin-bottom: 5px;
    max-height: 300px;
    min-height: 100px;
    overflow: auto;
    .map-search-content__list {
      border-radius: 0;
      .map-search-content__list-item {
        text-align: left;
        cursor: pointer;
      }
    }
  }
  .map-search-foot {
    display: flex;
    justify-content: flex-end;
  }
}
